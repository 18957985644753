<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Отправка сообщения пользователям в боте"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <b-form @submit.prevent="sendMessage">

          <div class="p-2">
            <b-alert
              show
              variant="warning"
            >
              <div class="alert-body">
                ВНИМАНИЕ! После отправки данной формы, пользователи с выбранной Вами ролью получат введенное сообщение
              </div>
            </b-alert>

            <!-- name -->
            <b-form-group
              label-for="message"
              label="Текст сообщения"
            >
              <b-form-textarea
                id="message"
                v-model.trim="form.message"
                name="name"
                rows="3"
                placeholder="Введите текст"
                autofocus
                required
              />
            </b-form-group>

            <!-- name -->
            <b-form-group
              label-for="text"
              label="Ссылка (необязательно)"
            >
              <b-form-textarea
                id="url"
                v-model.trim="form.url"
                name="name"
                rows="3"
                placeholder="Укажите адрес ссылки"
                autofocus
              />
            </b-form-group>

            <div>
              <label>Получатели</label>
            </div>
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="form.receiver_roles.includes(teacherRole) ? 'primary' : 'outline-primary'"
                @click="form.receiver_roles = [teacherRole]"
              >
                Только учителям
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="form.receiver_roles.includes(studentRole) ? 'primary' : 'outline-primary'"
                @click="form.receiver_roles = [studentRole]"
              >
                Только клиентам
              </b-button>
            </b-button-group>
          </div>
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              :disabled="!form.message"
            >
              Отправить
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormTextarea, BModal, BAlert, BButtonGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { toast } from '../../helpers'
import { teacher, student } from '../../store/user'

export default {
  name: 'TelegramMailingModal',
  components: {
    BModal,
    BButton,
    BForm,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    form: {
      message: null,
      url: null,
      receiver_roles: [],
    },
    display: false,
    sending: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    teacherRole() {
      return teacher
    },
    studentRole() {
      return student
    },
  },
  watch: {
    display() {
      this.form = {
        message: null,
        url: null,
        receiver_roles: [this.teacherRole],
      }
    },
  },
  methods: {
    async sendMessage() {
      const { message, url, receiver_roles } = this.form
      this.sending = true
      await this.$chatsHttp.post('/admin/mailing/message', { message, url, receiver_roles })
        .then(() => {
          toast({ title: 'Сообщение отправлено', type: 'success' })
          this.display = false
        })
      this.sending = false
    },
    show() {
      this.display = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
