<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="mb-1 mb-lg-0"
          >
            <v-select
              v-model="query.sortBy"
              :options="sortOptions"
              label="label"
              :clearable="false"
              :reduce="s => s.value"
              placeholder="Сортировка"
            />
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="query.sortOrder"
              :options="[{value: 'asc', label: 'По возрастанию'}, {value: 'desc', label: 'По убыванию'}]"
              label="label"
              :reduce="s => s.value"
              placeholder="Порядок"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <div class="d-flex align-items-center flex-wrap mt-2 mb-1 mb-md-0">
          <b-button
            :variant="!query.status ? 'primary' : 'flat-secondary'"
            class="mr-50"
            size="sm"
            @click="query.status = null"
          >
            Все
          </b-button>
          <b-button
            v-for="status in statuses"
            :key="status.value"
            :variant="query.status && query.status.includes(status.value) ? 'primary' : 'flat-secondary'"
            class="mr-50"
            size="sm"
            @click="query.status = [status.value]"
          >
            {{ status.label }}
          </b-button>
        </div>
      </b-col>
      <b-col class="d-none d-lg-flex" />
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-input-group class="bg-transparent">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            v-debounce:600ms="updateSearch"
            type="text"
            placeholder="Поиск..."
            class="bg-transparent"
          />
        </b-input-group>
        <div class="d-flex justify-content-end mt-2">
          <telegram-mailing-modal>
            <template #activator="{show}">
              <b-button
                variant="secondary"
                size="sm"
                @click="show"
              >
                <feather-icon
                  icon="SendIcon"
                  class="mr-50"
                />
                Создать рассылку
              </b-button>
            </template>
          </telegram-mailing-modal>
        </div>
      </b-col>
    </b-row>

    <lead-card
      v-for="lead in leads"
      :key="lead.id"
      :lead="lead"
    />

    <infinite-loading
      ref="infiniteLoader"
      @infinite="infiniteHandler"
    >
      <div slot="spinner">
        <lead-card-skeleton
          v-for="(skel, key) in 12"
          :key="key"
        />
      </div>
      <div slot="no-more" />
      <div slot="no-results">
        <h3 class="mt-2">
          <span v-if="!leads.length && query.search">Ничего не найдено по запросу <b>"{{ query.search }}"</b></span>
          <span v-else-if="!leads.length">Пока нет заявок</span>
        </h3>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import {
  BButton,
  BRow, BCol, BInputGroup, BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import LeadCard from '../../../components/leads/LeadCard.vue'
import LeadCardSkeleton from '../../../components/leads/LeadCardSkeleton.vue'
import {
  LEAD_STATUS_ACTIVE,
  LEAD_STATUS_ACTIVE_LABEL,
  LEAD_STATUS_DONE,
  LEAD_STATUS_DONE_LABEL,
} from '../../../shared/constants'
import TelegramMailingModal from '../../../components/leads/TelegramMailingModal.vue'

export default {
  name: 'AdminLeads',
  components: {
    TelegramMailingModal,
    LeadCardSkeleton,
    LeadCard,
    InfiniteLoading,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    vSelect,
    BButton,
  },
  data: () => ({
    leads: [],
    query: {
      limit: 12,
      sortBy: 'id',
      sortOrder: 'desc',
      search: '',
      status: null,
    },
    abortController: null,
  }),
  computed: {
    statuses() {
      return [
        {
          value: LEAD_STATUS_ACTIVE,
          label: LEAD_STATUS_ACTIVE_LABEL,
        },
        {
          value: LEAD_STATUS_DONE,
          label: LEAD_STATUS_DONE_LABEL,
        },
      ]
    },
    sortOptions() {
      return [
        {
          value: 'id',
          label: 'Номер заявки',
        },
        {
          value: 'subject_id',
          label: 'Предмет',
        },
        {
          value: 'grade_id',
          label: 'Класс',
        },
        {
          value: 'phone',
          label: 'Телефон',
        },
        {
          value: 'created',
          label: 'Дата создания',
        },
      ]
    },
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.leads = []
        this.$refs.infiniteLoader.stateChanger.reset()
      },
    },
  },
  methods: {
    updateSearch(value) {
      this.query.search = value ?? ''
    },
    infiniteHandler($state) {
      if (this.abortController) this.abortController.abort()
      this.abortController = new AbortController()
      const params = { ...this.query, offset: this.leads.length }

      this.$chatsHttp.get('/admin/leads', {
        signal: this.abortController.signal,
        params,
      }).then(async leads => {
        if (leads.length) {
          this.leads = [...this.leads, ...leads]
          if (leads.length < this.query.limit) $state.complete()
          else $state.loaded()
        } else {
          $state.complete()
        }
      }).catch(err => {
        if (err?.message !== 'canceled') $state.error()
      })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
